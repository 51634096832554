<template>
  <div id="profile">
    <div class="row ">
            <div class="col-lg-12 mb-3">
              <label  class="form-label">Senha atual</label>
              <input
                type="password"
                class="form-control"
                v-model="obj.senhaAtual"
                
            />
            </div>
            <div class="col-lg-12 mb-3">
              <label  class="form-label">Nova senha</label>
              <input
                type="password"
                class="form-control"
                v-model="obj.novaSenha"                
              />
            </div>
            <div class="col-lg-12 mb-3">
              <label  class="form-label">Confirmação da nova senha</label>
              <input
                type="password"
                class="form-control"
                v-model="obj.confirmacaoNovaSenha"              
                
              />
            </div>
            <div class="col-lg-12 btn-flex mb-3">
              <div class="d-grid gap-2">
                <button type="button" :disabled="sending" class="btn btn-primary send" @click="$emit(emiter)">
                  <span v-if="!sending">SALVAR SENHA</span>
                  <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
                  v-if="sending"/>
                </button>
              </div>
            </div>
          </div>
  </div>
</template>

<style scoped>
  
</style>
<script>

export default {
  name: 'PreferencesPassword',
  props: {
    sending: Boolean,
    emiter: String,
    obj: Object
  },
  data() {
    return {
    }
  },
  mounted(){
    this.$nextTick(function () {
      console.log('preferencesprofile mounted');
    })
  }
}

</script>