<template>
  <div id="person">
    <div class="row">
      <div class="col-lg-12 mb-3" v-if="errors.length">
        <ul>
          <li v-for="i in errors" :key="i">
            <label class="form-label text-danger">{{i}}</label>
          </li>
        </ul>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Nome/Razão social</label
        >
        <input
          type="email"
          class="form-control"
          v-model="obj.razaoSocial"
          
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Nome social(apelido)/Nome fantasia</label>
        <input
          type="text"
          class="form-control"
          v-model="obj.nomeFantasia"
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >CPF/CNPJ</label
        >
        <input
          type="text"
          class="form-control"
          v-model="obj.documento"    
          v-mask="['###.###.###-##', '##.###.###/####-##']"  
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Celular</label>
        <input
          type="tel"
          class="form-control"
          v-model="obj.telefoneCelular" 
          v-mask="['(##) ####-####', '(##) #####-####']"                  
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >E-mail</label
        >
        <input
          type="email"
          class="form-control"
          v-model="obj.email"   
          :readonly="edit"                          
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Estado</label
        >
        <select class="form-control" 
                @change="setCities(true)"
                v-model="obj.codigoEstado">
          <option v-for="i in states" :value="i.codigoEstado" :key="i.codigoEstado">
            {{i.estado}}
          </option>
        </select>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Cidade</label
        >
        <select class="form-control" 
                v-model="obj.codigoCidade">
          <option v-for="i in cities" :value="i.codigoCidade" :key="i.codigoCidade">
            {{i.nomeCidade}}
          </option>
        </select>
      </div>
      <div class="col-lg-12 btn-flex">
        <div class="d-grid gap-2">
          <button type="button" :disabled="sending" @click="submit" class="btn btn-primary send">
            <span v-if="!sending">{{buttonText || 'salvar' }}</span>
            <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
            v-if="sending"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-flex {
  display: flex;
  align-items: center;
}
.btn-flex > div {
  flex: 1;
}
#person {
  text-align: left
}
</style>

<script>
import {getItemOrFetch} from '@/services/localdb';
import {personKey,scrollWindow} from '@/services/util';

export default {
  data() {
    return {
      obj: {},
      states: [],
      cities: [],
      errors: []
    }
  },
  props: {
    buttonText: String,
    emiter: String,
    sending: Boolean,
    edit: Boolean,
    newUser: Object
  },
  methods: {
    validate() {
      this.errors = [];

      if(!this.obj.razaoSocial || this.obj.razaoSocial.length < 2)
        this.errors.push('Campo "Nome/Razão social" inválido');
    
      if(!this.obj.nomeFantasia || this.obj.nomeFantasia.length < 2)
        this.errors.push('Campo "Nome social(apelido)/Nome fantasia" inválido');

      if(!this.obj.documento || (this.obj.documento.length !== 14 && this.obj.documento.length !== 18))
        this.errors.push('Campo "CPF/CNPJ" inválido');
      
      if(!this.obj.email)
        this.errors.push('Campo "E-mail" inválido');

      if(!this.obj.telefoneCelular || this.obj.telefoneCelular.length < 14)
        this.errors.push('Campo "Celular" inválido');

      if(!this.obj.codigoCidade)
        this.errors.push('Campo "Cidade" inválido');

      return this.errors.length === 0;
    },
    submit() {
      if(this.emiter) {
        if(this.validate()) {
          this.$emit(this.emiter,this.obj);
        }
        else{
          alert('Erro na validação dos campos!')
        }
      }
    },
    async setCities(clearCity){
      const cityKey = 'city-'+this.obj.codigoEstado;
      
      if(clearCity) {
        this.obj.codigoCidade = '';
      }
      let savedData = await getItemOrFetch(cityKey,`/v1/cadastros/estados/${this.obj.codigoEstado}/cidades`);
      
      this.cities = savedData;
    }
  },
  async mounted() {
  this.obj = this.newUser || this.obj;
  const stateKey = 'state';
  this.states = await getItemOrFetch(stateKey,'/v1/cadastros/estados');
  if(this.obj.codigoCidade) {
      await this.setCities(false);
  }
  if(this.edit){
      
      this.obj = await getItemOrFetch(personKey,'/v1/lojistas');
      await this.setCities(false);
    }
  }
};
</script>