<template>
  <div id="profile">
    <div class="row">
      <div class="col-lg-12 mb-3" v-if="errors.length">
        <ul>
          <li v-for="i in errors" :key="i">
            <label class="form-label text-danger">{{i}}</label>
          </li>
        </ul>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Tempo de uso(em anos)</label>
        <div class="input-group">
          <span class="input-group-text" >Até</span>
            <money3 id="anoAte" v-model="obj.anoAte" class="form-control" v-bind="$root.money" placeholder="Ex.: 5"></money3>
          <span class="input-group-text" >anos de uso</span>
        </div>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Kilometragem</label>
        <div class="input-group">
          <span class="input-group-text" >Até</span>
            <money3 id="kmAte" v-model="obj.kmAte" class="form-control" v-bind="$root.money" placeholder="Ex.:  20.000"></money3>
          <span class="input-group-text" >km rodados</span>
        </div>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Valor mínimo</label>
        <div class="input-group">
          <span class="input-group-text" >Até</span>
            <money3 id="valorDe" v-model="obj.valorDe" class="form-control" v-bind="$root.money" placeholder="Ex.: 10.000"></money3>
          <span class="input-group-text" >reais</span>
        </div>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Valor máximo</label>
        <div class="input-group">
          <span class="input-group-text" >Até</span>
            <money3 id="valorAte" class="form-control" v-model="obj.valorAte" v-bind="$root.money" placeholder="Ex.: 20.000"></money3>
          <span class="input-group-text" >reais</span>
        </div>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Cidade preferida de compra</label>
        <div class="input-group">
          <select class="form-control" placeholder="Selecione uma cidade" v-model="obj.codigoCidadePreferida">
            <option v-for="c in cidades" :key="c.codigoCidade" :value="c.codigoCidade">
              {{c.nomeCidade}}/{{c.siglaEstado}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label">Ativar notificações</label>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="obj.notificar" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">
          </label>
        </div>
      </div>
      <div class="col-lg-12 btn-flex mb-3">
        <div class="d-grid gap-2">
          <button type="button" :disabled="sending" @click="submit" class="btn btn-primary send">
            <span v-if="!sending">{{buttonText || 'salvar' }}</span>
            <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
            v-if="sending"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
    #profile {
      text-align: left;
    }
</style>
<script>
import {filtroKey, profileKey,scrollWindow} from '@/services/util';
import {getItemOrFetch} from '@/services/localdb';

export default {
  name: 'PreferencesProfile',
  data() {
    return {
      obj: {},
      cidades: [],
      errors: []
    }
  },
  methods: {
    validate() {
      this.errors = [];

      if(!this.obj.anoAte || this.obj.anoAte.length === 0)
        this.errors.push('Campo "Tempo de uso" inválido');
    
      if(!this.obj.kmAte || this.obj.kmAte.length === 0)
        this.errors.push('Campo "Kilometragem" inválido');

      if(!this.obj.valorDe || this.obj.valorDe.length === 0)
        this.errors.push('Campo "Valor mínimo" inválido');
        
      if(!this.obj.valorAte || this.obj.valorAte.length === 0)
        this.errors.push('Campo "Valor máximo" inválido');

      if(!this.obj.codigoCidadePreferida)
        this.errors.push('Campo "Cidade preferida" inválido');

      return this.errors.length === 0;
    },
    submit(){
      if(this.emiter) {
        if(this.validate()) {
          this.$emit(this.emiter,this.obj);
        }
        else{
          alert('Erro na validação dos campos!')
        }
      }
    }
  },
  props: {
    buttonText: String,
    emiter: String,
    sending: Boolean,
    edit: Boolean,
    newUser: Object
  },
  
  async mounted() {
    const filtros =  await getItemOrFetch(filtroKey,'/v1/empresas/filtros');
    this.cidades = filtros.cidades;
    this.obj = this.newUser || this.obj;


    if(this.edit) {

      this.obj = await getItemOrFetch(profileKey,'/v1/lojistas/perfil');
      
      this.obj.codigoCidade = this.obj.cidades[0]?.codigoCidade;

    }
  }
}


</script>