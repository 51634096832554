<template>
  <div id="preferences">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <ul class="nav nav-pills">
          <li class="nav-item" v-for="i in tab.tabs" :key="i.id">
            <a  :class="'nav-link '+(tab.active === i.id ? 'active':'')" 
                href="#"
                @click="changeTab(i)"
                :disabled="sending"
                >{{i.label}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="body">
          <PreferencesPerson v-if="tab.active === 1" emiter="savePerson" :sending="sending" @savePerson="savePerson"  :edit="true"/>
          <PreferencesProfile v-if="tab.active === 2" emiter="saveProfile" :sending="sending" @saveProfile="saveProfile" :edit="true"/> 
          <PreferencesPassword v-if="tab.active === 3" emiter="changePwd" :sending="sending" @changePwd="changePwd" :obj="obj"/>
          
        </div>
      </div>
    </div>
    
  </div>
</template>

<style>
  #preferences .body {
    background: var(--white);
    min-height: 300px;
    border: 1px solid var(--light);
    margin-bottom: 1.5rem;
    padding:1.5rem;
    border-radius: 4px;
    

  }
  
  .nav{
    justify-content: center;
  }

  .nav-link {
    padding: 1rem 1.5rem;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var(--dark);
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: var(--primary-color);
} 

  @media (max-width: 992px){
    .nav-link {
      padding: 0.5rem 1rem;
    }
    
  }

  @media (max-width: 320px){
    .nav-link {
      font-size: 0.8rem
    }
    .nav {
      justify-content: space-between;
    }
  }

</style>
<script>
import api from '@/services/api';
import PreferencesPerson from '@/views/PreferencesPerson.vue'
import PreferencesProfile from '@/views/PreferencesProfile.vue'
import PreferencesPassword from '@/views/PreferencesPassword.vue'
import {setItem} from '@/services/localdb';
import {personKey, profileKey, scrollWindow} from '@/services/util';

export default {
  name: 'Preferences',
  components: {
    PreferencesPerson,
    PreferencesProfile,
    PreferencesPassword
  },
  data() {
    return {
      sending: false,

      tab: {
        active: 1,
        tabs: [
          {
            id: 1,
            label: 'Dados',
          },
          {
            id: 2,
            label: 'Perfil',
          },
          {
            id: 3,
            label: 'Trocar senha',
          }
        ]
      },
      obj: {
        senhaAtual: '',
        novaSenha: '',
        confirmacaoNovaSenha: '',
      },
    }
  },
  methods: {
    changeTab(item){
      this.tab.active=item.id;
    },
    async savePerson(person){
      this.sending = true;
      
      try{
        let response = await api.patch('/v1/lojistas',person);
        if(response.status == 204){
          await setItem(personKey,person);
          alert('Dados alterados com sucesso!');
        }
      }
      catch{}
      finally{
        this.sending = false;
      }
    },
    async saveProfile(profile){
      this.sending = true;
      
      try{
        let response = await api.put('/v1/lojistas/perfil',
        {
          ...profile,...{ kmAte: parseInt(profile.kmAte),
                          anoAte: parseInt(profile.anoAte), 
                          valorDe: parseInt(profile.valorDe), 
                          valorAte: parseInt(profile.valorAte)}
        });
        if(response.status == 204){
          await setItem(profileKey,profile);
          alert('Perfil alterado com sucesso!');
          scrollWindow(0);
        }
      }
      catch{}
      finally{
        this.sending = false;
      }
    },
    async changePwd(){
      if(this.validatePassword(this.obj)){
        this.sending = true;

        var response = await api.post(`/v1/usuarios/trocar-senha`,this.obj)
        if(response.status  == 204) {
        // this.$notify({
        //   title: 'Oferta enviada com sucesso!',
        //   text: 'Você poderá acompanhar a resposta na tela "Minhas Ofertas"',
        //   type: 'success'
        // });
          alert('Senha alterada com sucesso!');
          
          this.obj = {
            senhaAtual: '',
            novaSenha: '',
            confirmacaoNovaSenha: '',
          };
        }

        this.sending = false;
      }

    },
    validatePassword(obj) {
      if(obj.senhaAtual.length < 6){
        alert('A senha atual deve ter no mínimo 6 caracteres');
        return false;
      }
      if(obj.senhaAtual === obj.novaSenha){
        alert('A nova senha deve ser diferente da senha atual');
        return false;
      }
      if(!obj.novaSenha || obj.novaSenha.length < 6){
        alert('A nova senha deve ter no mínimo 6 caracteres');
        return false;
      }
      if(obj.novaSenha !== obj.confirmacaoNovaSenha){
        alert('O campo confirmação de senha deve ser idêntico ao campo nova senha.');
        return false;
      }

      return true;
    }
  },
  mounted(){
    this.$nextTick(function () {
      console.log('app mounted');
    })
  }
}

</script>